function _typeof(obj) {
    '@babel/helpers - typeof';
    return _typeof = 'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && 'function' == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
    }, _typeof(obj);
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        enumerableOnly && (symbols = symbols.filter(function (sym) {
            return Object.getOwnPropertyDescriptor(object, sym).enumerable;
        })), keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = null != arguments[i] ? arguments[i] : {};
        i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
            _defineProperty(target, key, source[key]);
        }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _defineProperty(obj, key, value) {
    key = _toPropertyKey(key);
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
function _toPropertyKey(arg) {
    var key = _toPrimitive(arg, 'string');
    return _typeof(key) === 'symbol' ? key : String(key);
}
function _toPrimitive(input, hint) {
    if (_typeof(input) !== 'object' || input === null)
        return input;
    var prim = input[Symbol.toPrimitive];
    if (prim !== undefined) {
        var res = prim.call(input, hint || 'default');
        if (_typeof(res) !== 'object')
            return res;
        throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return (hint === 'string' ? String : Number)(input);
}
import Promise from 'promise-polyfill';
import utils from './utils';
import handleOption from './options';
import { i18n } from './i18n';
import Template from './template';
import Icons from './icons';
import Danmaku from './danmaku';
import Events from './events';
import FullScreen from './fullscreen';
import User from './user';
import Subtitle from './subtitle';
import Subtitles from './subtitles';
import Bar from './bar';
import Timer from './timer';
import Bezel from './bezel';
import Controller from './controller';
import Setting from './setting';
import Comment from './comment';
import HotKey from './hotkey';
import ContextMenu from './contextmenu';
import InfoPanel from './info-panel';
import tplVideo from '../template/video.art';
var index = 0;
var instances = [];
var DPlayer = function () {
    function DPlayer(options) {
        var _this = this;
        _classCallCheck(this, DPlayer);
        this.options = handleOption(_objectSpread({ preload: options.video.type === 'webtorrent' ? 'none' : 'metadata' }, options));
        if (this.options.video.quality) {
            this.qualityIndex = this.options.video.defaultQuality;
            this.quality = this.options.video.quality[this.options.video.defaultQuality];
        }
        this.tran = new i18n(this.options.lang).tran;
        this.events = new Events();
        this.user = new User(this);
        this.container = this.options.container;
        this.noticeList = {};
        this.container.classList.add('dplayer');
        if (!this.options.danmaku) {
            this.container.classList.add('dplayer-no-danmaku');
        }
        if (this.options.live) {
            this.container.classList.add('dplayer-live');
        } else {
            this.container.classList.remove('dplayer-live');
        }
        if (utils.isMobile) {
            this.container.classList.add('dplayer-mobile');
        }
        this.arrow = this.container.offsetWidth <= 500;
        if (this.arrow) {
            this.container.classList.add('dplayer-arrow');
        }
        if (this.options.subtitle) {
            if (Array.isArray(this.options.subtitle.url)) {
                var offSubtitle = {
                    subtitle: '',
                    lang: 'off'
                };
                this.options.subtitle.url.push(offSubtitle);
                if (this.options.subtitle.defaultSubtitle) {
                    if (typeof this.options.subtitle.defaultSubtitle === 'string') {
                        this.options.subtitle.index = this.options.subtitle.url.findIndex(function (sub) {
                            return sub.lang === _this.options.subtitle.defaultSubtitle ? true : sub.name === _this.options.subtitle.defaultSubtitle ? true : false;
                        });
                    } else if (typeof this.options.subtitle.defaultSubtitle === 'number') {
                        this.options.subtitle.index = this.options.subtitle.defaultSubtitle;
                    }
                }
                if (this.options.subtitle.index === -1 || !this.options.subtitle.index || this.options.subtitle.index > this.options.subtitle.url.length - 1) {
                    this.options.subtitle.index = this.options.subtitle.url.findIndex(function (sub) {
                        return sub.lang === _this.options.lang;
                    });
                }
                if (this.options.subtitle.index === -1) {
                    this.options.subtitle.index = this.options.subtitle.url.length - 1;
                }
            }
        }
        this.template = new Template({
            container: this.container,
            options: this.options,
            index: index,
            tran: this.tran
        });
        this.video = this.template.video;
        this.bar = new Bar(this.template);
        this.bezel = new Bezel(this.template.bezel);
        this.fullScreen = new FullScreen(this);
        this.controller = new Controller(this);
        if (this.options.danmaku) {
            this.danmaku = new Danmaku({
                player: this,
                container: this.template.danmaku,
                opacity: this.user.get('opacity'),
                callback: function callback() {
                    setTimeout(function () {
                        _this.template.danmakuLoading.style.display = 'none';
                        if (_this.options.autoplay) {
                            _this.play();
                        }
                    }, 0);
                },
                error: function error(msg) {
                    _this.notice(msg);
                },
                apiBackend: this.options.apiBackend,
                borderColor: this.options.theme,
                height: this.arrow ? 24 : 30,
                time: function time() {
                    return _this.video.currentTime;
                },
                unlimited: this.user.get('unlimited'),
                api: {
                    id: this.options.danmaku.id,
                    address: this.options.danmaku.api,
                    token: this.options.danmaku.token,
                    maximum: this.options.danmaku.maximum,
                    addition: this.options.danmaku.addition,
                    user: this.options.danmaku.user,
                    speedRate: this.options.danmaku.speedRate
                },
                events: this.events,
                tran: function tran(msg) {
                    return _this.tran(msg);
                }
            });
            this.comment = new Comment(this);
        }
        this.setting = new Setting(this);
        this.plugins = {};
        this.docClickFun = function () {
            _this.focus = false;
        };
        this.containerClickFun = function () {
            _this.focus = true;
        };
        document.addEventListener('click', this.docClickFun, true);
        this.container.addEventListener('click', this.containerClickFun, true);
        this.paused = true;
        this.timer = new Timer(this);
        this.hotkey = new HotKey(this);
        this.contextmenu = new ContextMenu(this);
        this.initVideo(this.video, this.quality && this.quality.type || this.options.video.type);
        this.infoPanel = new InfoPanel(this);
        if (!this.danmaku && this.options.autoplay) {
            this.play();
        }
        this.moveBar = false;
        index++;
        instances.push(this);
    }
    _createClass(DPlayer, [
        {
            key: 'seek',
            value: function seek(time) {
                time = Math.max(time, 0);
                if (this.video.duration) {
                    time = Math.min(time, this.video.duration);
                }
                if (this.video.currentTime < time) {
                    this.notice(''.concat(this.tran('ff').replace('%s', (time - this.video.currentTime).toFixed(0))));
                } else if (this.video.currentTime > time) {
                    this.notice(''.concat(this.tran('rew').replace('%s', (this.video.currentTime - time).toFixed(0))));
                }
                this.video.currentTime = time;
                if (this.danmaku) {
                    this.danmaku.seek();
                }
                this.bar.set('played', time / this.video.duration, 'width');
                this.template.ptime.innerHTML = utils.secondToTime(time);
            }
        },
        {
            key: 'play',
            value: function play(fromNative) {
                var _this2 = this;
                this.paused = false;
                if (this.video.paused && !utils.isMobile) {
                    this.bezel['switch'](Icons.play);
                }
                this.template.playButton.innerHTML = Icons.pause;
                this.template.mobilePlayButton.innerHTML = Icons.pause;
                if (!fromNative) {
                    var playedPromise = Promise.resolve(this.video.play());
                    playedPromise['catch'](function () {
                        _this2.pause();
                    }).then(function () {
                    });
                }
                this.timer.enable('loading');
                this.container.classList.remove('dplayer-paused');
                this.container.classList.add('dplayer-playing');
                if (this.danmaku) {
                    this.danmaku.play();
                }
                if (this.options.mutex) {
                    for (var i = 0; i < instances.length; i++) {
                        if (this !== instances[i]) {
                            instances[i].pause();
                        }
                    }
                }
            }
        },
        {
            key: 'pause',
            value: function pause(fromNative) {
                this.paused = true;
                this.container.classList.remove('dplayer-loading');
                if (!this.video.paused && !utils.isMobile) {
                    this.bezel['switch'](Icons.pause);
                }
                this.template.playButton.innerHTML = Icons.play;
                this.template.mobilePlayButton.innerHTML = Icons.play;
                if (!fromNative) {
                    this.video.pause();
                }
                this.timer.disable('loading');
                this.container.classList.remove('dplayer-playing');
                this.container.classList.add('dplayer-paused');
                if (this.danmaku) {
                    this.danmaku.pause();
                }
            }
        },
        {
            key: 'switchVolumeIcon',
            value: function switchVolumeIcon() {
                if (this.volume() >= 0.95) {
                    this.template.volumeIcon.innerHTML = Icons.volumeUp;
                } else if (this.volume() > 0) {
                    this.template.volumeIcon.innerHTML = Icons.volumeDown;
                } else {
                    this.template.volumeIcon.innerHTML = Icons.volumeOff;
                }
            }
        },
        {
            key: 'volume',
            value: function volume(percentage, nostorage, nonotice) {
                percentage = parseFloat(percentage);
                if (!isNaN(percentage)) {
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    this.bar.set('volume', percentage, 'width');
                    var formatPercentage = ''.concat((percentage * 100).toFixed(0), '%');
                    this.template.volumeBarWrapWrap.dataset.balloon = formatPercentage;
                    if (!nostorage) {
                        this.user.set('volume', percentage);
                    }
                    if (!nonotice) {
                        this.notice(''.concat(this.tran('volume'), ' ').concat((percentage * 100).toFixed(0), '%'), undefined, undefined, 'volume');
                    }
                    this.video.volume = percentage;
                    if (this.video.muted) {
                        this.video.muted = false;
                    }
                    this.switchVolumeIcon();
                }
                return this.video.volume;
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                if (this.video.paused) {
                    this.play();
                } else {
                    this.pause();
                }
            }
        },
        {
            key: 'on',
            value: function on(name, callback) {
                this.events.on(name, callback);
            }
        },
        {
            key: 'switchVideo',
            value: function switchVideo(video, danmakuAPI) {
                this.pause();
                this.video.poster = video.pic ? video.pic : '';
                this.video.src = video.url;
                this.initMSE(this.video, video.type || 'auto');
                if (danmakuAPI) {
                    this.template.danmakuLoading.style.display = 'block';
                    this.bar.set('played', 0, 'width');
                    this.bar.set('loaded', 0, 'width');
                    this.template.ptime.innerHTML = '00:00';
                    this.template.danmaku.innerHTML = '';
                    if (this.danmaku) {
                        this.danmaku.reload({
                            id: danmakuAPI.id,
                            address: danmakuAPI.api,
                            token: danmakuAPI.token,
                            maximum: danmakuAPI.maximum,
                            addition: danmakuAPI.addition,
                            user: danmakuAPI.user
                        });
                    }
                }
            }
        },
        {
            key: 'initMSE',
            value: function initMSE(video, type) {
                var _this3 = this;
                this.type = type;
                if (this.options.video.customType && this.options.video.customType[type]) {
                    if (Object.prototype.toString.call(this.options.video.customType[type]) === '[object Function]') {
                        this.options.video.customType[type](this.video, this);
                    } else {
                        console.error('Illegal customType: '.concat(type));
                    }
                } else {
                    if (this.type === 'auto') {
                        if (/m3u8(#|\?|$)/i.exec(video.src)) {
                            this.type = 'hls';
                        } else if (/.flv(#|\?|$)/i.exec(video.src)) {
                            this.type = 'flv';
                        } else if (/.mpd(#|\?|$)/i.exec(video.src)) {
                            this.type = 'dash';
                        } else {
                            this.type = 'normal';
                        }
                    }
                    if (this.type === 'hls' && (video.canPlayType('application/x-mpegURL') || video.canPlayType('application/vnd.apple.mpegURL'))) {
                        this.type = 'normal';
                    }
                    switch (this.type) {
                    case 'hls':
                        if (window.Hls) {
                            if (window.Hls.isSupported()) {
                                var options = this.options.pluginOptions.hls;
                                var hls = new window.Hls(options);
                                this.plugins.hls = hls;
                                hls.loadSource(video.src);
                                hls.attachMedia(video);
                                this.events.on('destroy', function () {
                                    hls.destroy();
                                    delete _this3.plugins.hls;
                                });
                            } else {
                                this.notice('Error: Hls is not supported.');
                            }
                        } else {
                            this.notice('Error: Can\'t find Hls.');
                        }
                        break;
                    case 'flv':
                        if (window.flvjs) {
                            if (window.flvjs.isSupported()) {
                                var flvPlayer = window.flvjs.createPlayer(Object.assign(this.options.pluginOptions.flv.mediaDataSource || {}, {
                                    type: 'flv',
                                    url: video.src
                                }), this.options.pluginOptions.flv.config);
                                this.plugins.flvjs = flvPlayer;
                                flvPlayer.attachMediaElement(video);
                                flvPlayer.load();
                                this.events.on('destroy', function () {
                                    flvPlayer.unload();
                                    flvPlayer.detachMediaElement();
                                    flvPlayer.destroy();
                                    delete _this3.plugins.flvjs;
                                });
                            } else {
                                this.notice('Error: flvjs is not supported.');
                            }
                        } else {
                            this.notice('Error: Can\'t find flvjs.');
                        }
                        break;
                    case 'dash':
                        if (window.dashjs) {
                            var dashjsPlayer = window.dashjs.MediaPlayer().create().initialize(video, video.src, false);
                            var _options = this.options.pluginOptions.dash;
                            dashjsPlayer.updateSettings(_options);
                            this.plugins.dash = dashjsPlayer;
                            this.events.on('destroy', function () {
                                window.dashjs.MediaPlayer().reset();
                                delete _this3.plugins.dash;
                            });
                        } else {
                            this.notice('Error: Can\'t find dashjs.');
                        }
                        break;
                    case 'webtorrent':
                        if (window.WebTorrent) {
                            if (window.WebTorrent.WEBRTC_SUPPORT) {
                                this.container.classList.add('dplayer-loading');
                                var _options2 = this.options.pluginOptions.webtorrent;
                                var client = new window.WebTorrent(_options2);
                                this.plugins.webtorrent = client;
                                var torrentId = video.src;
                                video.src = '';
                                video.preload = 'metadata';
                                video.addEventListener('durationchange', function () {
                                    return _this3.container.classList.remove('dplayer-loading');
                                }, { once: true });
                                client.add(torrentId, function (torrent) {
                                    var file = torrent.files.find(function (file) {
                                        return file.name.endsWith('.mp4');
                                    });
                                    file.renderTo(_this3.video, {
                                        autoplay: _this3.options.autoplay,
                                        controls: false
                                    });
                                });
                                this.events.on('destroy', function () {
                                    client.remove(torrentId);
                                    client.destroy();
                                    delete _this3.plugins.webtorrent;
                                });
                            } else {
                                this.notice('Error: Webtorrent is not supported.');
                            }
                        } else {
                            this.notice('Error: Can\'t find Webtorrent.');
                        }
                        break;
                    }
                }
            }
        },
        {
            key: 'initVideo',
            value: function initVideo(video, type) {
                var _this4 = this;
                this.initMSE(video, type);
                this.on('durationchange', function () {
                    if (video.duration !== 1 && video.duration !== Infinity) {
                        _this4.template.dtime.innerHTML = utils.secondToTime(video.duration);
                    }
                });
                this.on('progress', function () {
                    var percentage = video.buffered.length ? video.buffered.end(video.buffered.length - 1) / video.duration : 0;
                    _this4.bar.set('loaded', percentage, 'width');
                });
                this.on('error', function () {
                    if (!_this4.video.error) {
                        return;
                    }
                    _this4.tran && _this4.notice && _this4.type !== 'webtorrent' && _this4.notice(_this4.tran('video-failed'));
                });
                this.on('ended', function () {
                    _this4.bar.set('played', 1, 'width');
                    if (!_this4.setting.loop) {
                        _this4.pause();
                    } else {
                        _this4.seek(0);
                        _this4.play();
                    }
                    if (_this4.danmaku) {
                        _this4.danmaku.danIndex = 0;
                    }
                });
                this.on('play', function () {
                    if (_this4.paused) {
                        _this4.play(true);
                    }
                });
                this.on('pause', function () {
                    if (!_this4.paused) {
                        _this4.pause(true);
                    }
                });
                this.on('timeupdate', function () {
                    if (!_this4.moveBar) {
                        _this4.bar.set('played', _this4.video.currentTime / _this4.video.duration, 'width');
                    }
                    var currentTime = utils.secondToTime(_this4.video.currentTime);
                    if (_this4.template.ptime.innerHTML !== currentTime) {
                        _this4.template.ptime.innerHTML = currentTime;
                    }
                });
                var _loop = function _loop(i) {
                    video.addEventListener(_this4.events.videoEvents[i], function (e) {
                        _this4.events.trigger(_this4.events.videoEvents[i], e);
                    });
                };
                for (var i = 0; i < this.events.videoEvents.length; i++) {
                    _loop(i);
                }
                this.volume(this.user.get('volume'), true, true);
                if (this.options.subtitle) {
                    this.subtitle = new Subtitle(this.template.subtitle, this.video, this.options.subtitle, this.events);
                    if (Array.isArray(this.options.subtitle.url)) {
                        this.subtitles = new Subtitles(this);
                    }
                    if (!this.user.get('subtitle')) {
                        this.subtitle.hide();
                    }
                }
            }
        },
        {
            key: 'switchQuality',
            value: function switchQuality(index) {
                var _this5 = this;
                index = typeof index === 'string' ? parseInt(index) : index;
                if (this.qualityIndex === index || this.switchingQuality) {
                    return;
                } else {
                    this.prevIndex = this.qualityIndex;
                    this.qualityIndex = index;
                }
                this.switchingQuality = true;
                this.quality = this.options.video.quality[index];
                this.template.qualityButton.innerHTML = this.quality.name;
                var paused = this.video.paused;
                this.video.pause();
                var videoHTML = tplVideo({
                    current: false,
                    pic: null,
                    screenshot: this.options.screenshot,
                    preload: 'auto',
                    url: this.quality.url,
                    subtitle: this.options.subtitle
                });
                var videoEle = new DOMParser().parseFromString(videoHTML, 'text/html').body.firstChild;
                this.template.videoWrap.insertBefore(videoEle, this.template.videoWrap.getElementsByTagName('div')[0]);
                this.prevVideo = this.video;
                this.video = videoEle;
                this.initVideo(this.video, this.quality.type || this.options.video.type);
                this.seek(this.prevVideo.currentTime);
                this.notice(''.concat(this.tran('switching-quality').replace('%q', this.quality.name)), -1, undefined, 'switch-quality');
                this.events.trigger('quality_start', this.quality);
                this.on('canplay', function () {
                    if (_this5.prevVideo) {
                        if (_this5.video.currentTime !== _this5.prevVideo.currentTime) {
                            _this5.seek(_this5.prevVideo.currentTime);
                            return;
                        }
                        _this5.template.videoWrap.removeChild(_this5.prevVideo);
                        _this5.video.classList.add('dplayer-video-current');
                        if (!paused) {
                            _this5.video.play();
                        }
                        _this5.prevVideo = null;
                        _this5.notice(''.concat(_this5.tran('switched-quality').replace('%q', _this5.quality.name)), undefined, undefined, 'switch-quality');
                        _this5.switchingQuality = false;
                        _this5.events.trigger('quality_end');
                    }
                });
                this.on('error', function () {
                    if (!_this5.video.error) {
                        return;
                    }
                    if (_this5.prevVideo) {
                        _this5.template.videoWrap.removeChild(_this5.video);
                        _this5.video = _this5.prevVideo;
                        if (!paused) {
                            _this5.video.play();
                        }
                        _this5.qualityIndex = _this5.prevIndex;
                        _this5.quality = _this5.options.video.quality[_this5.qualityIndex];
                        _this5.noticeTime = setTimeout(function () {
                            _this5.template.notice.style.opacity = 0;
                            _this5.events.trigger('notice_hide');
                        }, 3000);
                        _this5.prevVideo = null;
                        _this5.switchingQuality = false;
                    }
                });
            }
        },
        {
            key: 'notice',
            value: function notice(text) {
                var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2000;
                var opacity = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0.8;
                var id = arguments.length > 3 ? arguments[3] : undefined;
                var oldNoticeEle;
                if (id) {
                    oldNoticeEle = document.getElementById('dplayer-notice-'.concat(id));
                    if (oldNoticeEle) {
                        oldNoticeEle.innerHTML = text;
                    }
                    if (this.noticeList[id]) {
                        clearTimeout(this.noticeList[id]);
                        this.noticeList[id] = null;
                    }
                }
                if (!oldNoticeEle) {
                    var notice = Template.NewNotice(text, opacity, id);
                    this.template.noticeList.appendChild(notice);
                    oldNoticeEle = notice;
                }
                this.events.trigger('notice_show', oldNoticeEle);
                if (time > 0) {
                    this.noticeList[id] = setTimeout(function (e, dp) {
                        return function () {
                            e.addEventListener('animationend', function () {
                                dp.template.noticeList.removeChild(e);
                            });
                            e.classList.add('remove-notice');
                            dp.events.trigger('notice_hide');
                            dp.noticeList[id] = null;
                        };
                    }(oldNoticeEle, this), time);
                }
            }
        },
        {
            key: 'resize',
            value: function resize() {
                if (this.danmaku) {
                    this.danmaku.resize();
                }
                if (this.controller.thumbnails) {
                    this.controller.thumbnails.resize(160, this.video.videoHeight / this.video.videoWidth * 160, this.template.barWrap.offsetWidth);
                }
                this.events.trigger('resize');
            }
        },
        {
            key: 'speed',
            value: function speed(rate) {
                this.video.playbackRate = rate;
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                instances.splice(instances.indexOf(this), 1);
                this.pause();
                document.removeEventListener('click', this.docClickFun, true);
                this.container.removeEventListener('click', this.containerClickFun, true);
                this.fullScreen.destroy();
                this.hotkey.destroy();
                this.contextmenu.destroy();
                this.controller.destroy();
                this.timer.destroy();
                this.video.src = '';
                this.container.innerHTML = '';
                this.events.trigger('destroy');
            }
        }
    ], [{
            key: 'version',
            get: function get() {
                return DPLAYER_VERSION;
            }
        }]);
    return DPlayer;
}();
export default DPlayer;